import classes from './Reports.module.scss'
import report_classes from '../Admin/Reports.module.scss'

import search_icon from '../../assets/icons/search.svg'
import arrow_left from '../../assets/icons/arrow_left.svg'
import updown_icon from '../../assets/icons/updown.svg'
import down_icon from '../../assets/icons/arrow_down.svg'
import arrow_right from '../../assets/icons/arrow_right.svg'
// import up_icon from '../../assets/icons/arrow_up.svg'


import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import moment from 'moment'
import axios from 'axios'
import { round } from '@amcharts/amcharts4/.internal/core/utils/Math'
import { DateRangePicker, Col, Grid, Row } from 'rsuite'
import subDays from 'date-fns/subDays'
import htmlparser from 'html-react-parser'
import { ImpulseSpinner } from "react-spinners-kit"
import { ToastContainer, toast } from 'react-toastify'
import DashboardCard from "../Cards/DashboardCard"
import LoadingTable from '../LoadingTable/LoadingTable'

import conversions_icon from '../../assets/icons/conversions_white.svg'
import revenue_icon from '../../assets/icons/dollar_white.svg'
import users_solid from '../../assets/icons/users-new-solid.svg'
import ad_impression_icon from '../../assets/icons/eye_white.svg'


import addDays from 'date-fns/addDays'
import { useCookies } from 'react-cookie'

import ResponsiveTable from '../Customs/ResponsiveTable/ResponsiveTable'
import { handlePagination, displayEntriesDiv } from '../../utils/helper_functions'
import useWindowSize from '../../assets/hooks/useWindowSize.js'


const Reports = () => {
    let { width } = useWindowSize()
    // required variables and names
    const names = useSelector(state => state.ui.language.values.reportsContent)

    // "Average<br>Engagments<br>Time Per<br>Session", "Engaged<br>Sessions<br>Per User",
    const report_table_headers = [
        // "#", "Offer Name", "Users", "Sessions", "Total Clicks", "Unique Clicks",
        "#", "Offer Name", "Total Visits", "Unique Visits",
        "Conversions", "Conversion Rate",
         "EPC",
        "Total<br/>Revenue"
    ]
    const breakdown_table_headers = ["Users", "Sessions", "Ad<br>Impressions", "Ad<br>Revenue"]
    
    const report_table_sort_key = ["#", "name", "totalUsers", "totalSessions", "totalAdImpressions", "totalAdRevenue"]
    const breakdown_table_sort_key = ["totalUsers", "totalSessions", "totalAdImpressions", "totalAdRevenue"]



    const API_URL = process.env.REACT_APP_API_URL

    const [loadingResults, setLoadingResults] = useState(false)
    const [breakdownReportLoading, setBreakdownReportLoading] = useState(false)

    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)

    // sorting states
    const [sortKeys, setSortKeys] = useState([])
    const [sortOrder, setSortOrder] = useState('desc')
    const [sortBy, setSortBy] = useState('')
    
    // popup states
    const [reloadMainReport, setReloadMainReport] = useState(false)
    const [showBreakdownReport, setShowBreakdownReport] = useState(false)
    const [targetOfferName, setTargetOfferName] = useState('')
    const [targetOffer, setTargetOffer] = useState()
    const [targetOfferCategory, setTargetOfferCategory] = useState("")

    const [searchQuery, setSearchQuery] = useState('')
    const [searchParameter, setSearchParameter] = useState('country')

    const [tdIndex, setTdIndex] = useState('')


    // pagination section states
    const [rowsSent, setRowsSent] = useState(1)
    const [totalRows, setTotalRows] = useState(1)
    const [perPage, setPerPage] = useState(50) 
    const [totalPages, setTotalPages] = useState(1)
    const [pageNumber, setPageNumber] = useState(1)
    // const [paginationMultiplyNum, setPaginationMultiplyNum] = useState(0)

    const [paginationStart, setPaginationStart] = useState(false)

    // backup states for pagination
    const [backupPageNumber, setBackupPageNumber] = useState(1)
    // const [backupTotalRows, setBackupTotalRows] = useState(1)
    const [reportBackupTotalRows, setReportBackupTotalRows] = useState(1)


    // data states
    const [Data, setData] = useState([])
    const [breakdownData, setBreakdownData] = useState([])
    const [backupData, setBackupData] = useState([])


    // date range states
    const [cookies, setCookie] = useCookies(['report_dates'])
    const [selectionRange, setSelectionRange] = useState([
        cookies.report_dates?new Date(cookies.report_dates[0]):new Date(new Date().setDate(new Date().getDate() - 7)),
        cookies.report_dates?new Date(cookies.report_dates[1]):new Date()
    ])

    // ##################### generate random data #####################
    // const generateRandomData = () => {
    //     const data = []
    //     for (let i = 0; i < 30; i++) {
    //         const randomData = {
    //             "offer_name" : "Offer " + (i + 1),
    //             "session_source_medium": "UTM",
    //             "users": ((i + 1) * Math.floor(Math.random(1000, 3000) * 1000)),
    //             "sessions": Math.floor(Math.random() * 100),
    //             "engaged_sessions": Math.floor(Math.random() * 50),
    //             "average_engagments_time_per_session": Math.floor(Math.random() * 200),
    //             "engaged_sessions_per_user": Math.floor(Math.random() * 10),
    //             "events_per_session": Math.floor(Math.random() * 20),
    //             "events_count": Math.floor(Math.random() * 500),
    //             "ad_revenue": Math.floor(Math.random() * 1000),
    //             "cpa_revenue": Math.floor(Math.random() * 100)
    //         }
    //         data.push(randomData)
    //     }
    //     return data
    // }
    // const [Data, setData] = useState([
    //     {
    //         "offer_name" : "FitAI", 
    //         "session_source_medium": "UTM", 
    //         "users": "8744",
    //         "sessions": "12",
    //         "engaged_sessions": "7",
    //         "average_engagments_time_per_session": "123",
    //         "engaged_sessions_per_user": "5",
    //         "events_per_session": "13",
    //         "events_count": "273",
    //         "ad_revenue": "374",
    //         "cpa_revenue": "44"
    //     },
    //     ...generateRandomData()
    // ])
    
    // const generateBreakdownData = () => {
    //     const data = []
    //     for (let i = 0; i < 30; i++) {
    //         const randomData = {
    //             "session_source_medium": "UTM",
    //             "users": ((i + 1) * Math.floor(Math.random(1000, 3000) * 1000)),
    //             "sessions": Math.floor(Math.random() * 100),
    //             "engaged_sessions": Math.floor(Math.random() * 50),
    //             "average_engagments_time_per_session": Math.floor(Math.random() * 200),
    //             "engaged_sessions_per_user": Math.floor(Math.random() * 10),
    //             "events_per_session": Math.floor(Math.random() * 20),
    //             "events_count": Math.floor(Math.random() * 500),
    //             "ad_revenue": Math.floor(Math.random() * 1000),
    //             "cpa_revenue": Math.floor(Math.random() * 100)
    //         }
    //         data.push(randomData)
    //     }
    //     return data
    // }
    // const [breakdownData, setBreakdownData] = useState([
    //     {
    //         "session_source_medium": "UTM", 
    //         "users": "5674",
    //         "sessions": "12",
    //         "engaged_sessions": "7",
    //         "average_engagments_time_per_session": "123",
    //         "engaged_sessions_per_user": "5",
    //         "events_per_session": "13",
    //         "events_count": "273",
    //         "ad_revenue": "374",
    //         "cpa_revenue": "44"
    //     },
    //     ...generateBreakdownData()
    // ])
    


        
        
        
        

        
    // ############################# custom report headers #############################
    const handleSearchParameterChange = (e) => {
        setSearchParameter(e.target.value)
        clear_sort_values()
    }

    const handleSearchQuery = (e) => {
        let search_query = e.target.value
        setSearchQuery(search_query)
        clear_sort_values()

        if(searchParameter && search_query!==''){
            let search_data = backupData.filter((each_data) => {
                return each_data[searchParameter].toLowerCase().includes(search_query.toLowerCase())
            })

            setBreakdownData(search_data)
            setPageNumber(1)
            setThePaginationParameters(search_data.length, perPage)
            setTotalRows(backupData.length)
        }else{
            // restore from backup

            var target_data = backupData.slice(perPage*(pageNumber-1), perPage*pageNumber)
            setBreakdownData(target_data)
            setThePaginationParameters(target_data.length, backupData.length)
        }

    }




    const clear_sort_values = () => {
        setSortOrder('desc')
        setSortBy('')
        setTdIndex('')
    }
    const clear_pagination_values = () => {
        setPerPage(50)  
        setRowsSent(1)
        setTotalRows(1)
        setPageNumber(1)
        setTotalPages(1)
        setPaginationStart(false)
    }

    const handleSorting = (target_index) => {
        // console.log("----sortkeys-----", target_index, sortKeys, sortKeys.length)

        if (target_index === '') return

        var target_sort_key = sortKeys[target_index]
        var targetSortOrder = sortOrder

        if (target_sort_key !== sortBy){
            targetSortOrder = 'desc'
            setSortOrder('desc')
        }else{
            if(sortOrder === 'asc'){
                targetSortOrder = 'desc'
                setSortOrder('desc')
            }else{
                targetSortOrder = 'asc'
                setSortOrder('asc')
            }
        }
        // console.log("sorting... :", target_sort_key, targetSortOrder)
        
        var sortData = showBreakdownReport ? breakdownData : Data
        sortData.sort((a, b) => {            
            if (a[target_sort_key] < b[target_sort_key]) {
                return targetSortOrder === 'asc' ? -1 : 1
            }
            if (a[target_sort_key] > b[target_sort_key]) {
                return targetSortOrder === 'asc' ? 1 : -1
            }
            return 0
        })

        // change sort by key name
        setSortBy(target_sort_key)
    }



    const handleMouseLeave = (target_index) => {
        if (sortBy === sortKeys[target_index]){

        }else{
            setTdIndex('')
        }
    }
    const CustomReportHeaders = (data) => {
        let headers_names = Object.entries(data.header_names)
        return (
            <>
                {headers_names.map((each_item, index)=>{
                    return (
                        <th key={index} className={report_classes.cursor_pointer} onClick={() => handleSorting(index)} onMouseOver={()=> setTdIndex(index)} onMouseLeave={() => handleMouseLeave(index)}>
                            {htmlparser(each_item[1])}
                            {(index===tdIndex || sortBy===sortKeys[index]) && each_item[1]!=='#' && breakdownData.length>0 && (
                            <>
                                {sortBy===sortKeys[index]?(
                                    <>
                                    {sortOrder==='asc' && <img className={classes.sort_icon_asc} src={down_icon} alt="sort"/>}
                                    {sortOrder==='desc' && <img className={classes.sort_icon_desc} src={down_icon} alt="sort"/>}
                                    </>
                                ):(
                                    <img className={classes.sort_icon} src={updown_icon} alt="sort"/>
                                )}
                            </>
                            )}                            
                        </th>
                    )
                })}
            </>
        )
    } 

    const ReportHeaders = (data) => {
        const showInsight = data.showInsight
        let headers_names = Object.entries(data.header_names)  
        
        return (
            <tr>    
                {headers_names.map((each_item, index)=>{
                    if(each_item[0]==='offer' || each_item[0]==='source_medium'){
                        if(showInsight && each_item[0]==='source_medium'){
                            return  <th key={index}>{htmlparser(each_item[1])}</th>
                        }else if(!showInsight && each_item[0]==='offer'){
                            return  <th key={index}>{htmlparser(each_item[1])}</th>
                        }
                    }else{
                        return  <th key={index} className={each_item[1]==='#'?"":report_classes.cursor_pointer} onClick={each_item[1]==='#'? () => handleSorting(''):() => handleSorting(index)} onMouseOver={()=> setTdIndex(index)} onMouseLeave={()=> setTdIndex('')}>
                                    {htmlparser(each_item[1])}

                                    {(index===tdIndex || sortBy===sortKeys[index]) && each_item[1]!=='#' && Data.length>0 && (
                                        <>
                                            {sortBy===sortKeys[index]?(
                                                <>
                                                {sortOrder==='asc' && <img className={classes.sort_icon_asc} src={down_icon} alt="sort"/>}
                                                {sortOrder==='desc' && <img className={classes.sort_icon_desc} src={down_icon} alt="sort"/>}
                                                </>
                                            ):(
                                                <img className={classes.sort_icon} src={updown_icon} alt="sort"/>
                                            )}
                                        </>
                                    )}
                                </th>
                    }
                })}
            </tr>
        )
    }
    //  <img className={classes.sort_icon} src={updown_icon} alt="sort"/>}


    


    // ############################# get reports by query #############################
    const predefinedRanges = [
        {
          label: 'Today',
          value: [new Date(), new Date()],
          appearance: 'default'
        },
        {
          label: 'Yesterday',
          value: [addDays(new Date(), -1), addDays(new Date(), -1)],
          appearance: 'default'
        },
        {
          label: 'Last 7 days',
          value: [subDays(new Date(), 6), new Date()],
          appearance: 'default'
        },
        {
          label: 'Last 30 days',
          value: [subDays(new Date(), 29), new Date()],
          appearance: 'default'
        },
        {
          label: 'All time',
          value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date()],
          appearance: 'default'
        }
    ]
    





    let handleSelect = (dates) => {
        setCookie('report_dates', dates)
        setSelectionRange(dates)
        
        if(dates){
            setPageNumber(1)
            if(showBreakdownReport){
                get_target_offer_report(dates)
                setReloadMainReport(true)
            }else{
                getReports(dates)
            }
        }
    }



    const process_selection_dates = (dates, selectionRange) => {  
        var start_date, end_date

        if(dates){
            start_date = moment(dates[0]).format('YYYY-MM-DD')
            end_date =  moment(dates[1]).format('YYYY-MM-DD')
        }else{
            if(selectionRange){
                start_date = moment(selectionRange[0]).format('YYYY-MM-DD')
                end_date =  moment(selectionRange[1]).format('YYYY-MM-DD')

            }else{
                show_date_range_error()
                start_date = false
                end_date = false
            }
        }
        return [start_date, end_date]
    }  

    // ############################# get reports data via api #############################
    const getReports = async (dates) => {
        setLoadingResults(true)
        
        var returned_selection_dates = process_selection_dates(dates, selectionRange)
        var start_date = returned_selection_dates[0]
        var end_date = returned_selection_dates[1]
        if(!start_date || !end_date){ 
            setLoadingResults(false)
            return 
        }
        
        // console.log(start_date, end_date)
        let url = `${API_URL}/api/v1/reports?start_date=${start_date}&end_date=${end_date}&per_page=${perPage}&page_number=${pageNumber}`
        let token = localStorage.getItem('access_token')
        let config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: url,
            headers: { 
              'Authorization': 'Bearer '+token
            }
        }
        
        axios.request(config)
        .then((response) => {
            if(response.data.reports){
                // console.log('\n\n------------>', response.data.reports)
                setData(response.data.reports)
                set_total_data_top(response.data.total_data_map)
                setThePaginationParameters(response.data.reports.length, response.data.total_reports)
            }
            setLoadingResults(false)
        })
        .catch((error) => {
            if(error && error.response && error.response.status > 400 ){
                setLoadingResults(false)
            }
        })

        setReloadMainReport(false)
    }



    const setThePaginationParameters = (rows_sent, total_rows) => {
        // setBackupTotalRows(total_rows)
        
        setRowsSent(rows_sent)
        setTotalRows(total_rows)
        
        if(total_rows < perPage){
            setTotalPages(1)
        }else{
            setTotalPages(Math.ceil(total_rows/perPage))
        }
    }
    
    const show_date_range_error = () => {
        if(!error){
            toast.error('Date is required.')
            setError(true)
            setTimeout(() => { setError(false) }, 3000)
        }
    }
    

    // ############################# default useEffect #############################
    useEffect(() => {
        document.title = "LinkVantage | Reports"
        getReports()
    }, [])

    // ############################# get users data when page number is changed #############################
    useEffect(()=>{
        if(paginationStart){
            setPaginationStart(false)
            if(showBreakdownReport){
                var target_data = backupData.slice(perPage*(pageNumber-1), perPage*pageNumber)
                setBreakdownData(target_data)
                setBreakdownReportLoading(true)
                setThePaginationParameters(target_data.length, backupData.length)

                setTimeout(() => {setBreakdownReportLoading(false)}, 1000);
            }else{
                getReports()
            }
        }
    }, [perPage, pageNumber]) 



    useEffect(() => {
        if(showBreakdownReport){
            setSortKeys([...breakdown_table_sort_key])
        }else{
            setSortKeys([...report_table_sort_key])

            if(reloadMainReport){
                getReports()
            }
        }
    }, [showBreakdownReport])


    // useEffect(()=>{
    //     if((!showBreakdownReport && !loadingResults) || (showBreakdownReport && !breakdownReportLoading)){
    //         console.log('page is greater than 1 :', pageNumber, perPage, (pageNumber-1)*perPage)
    //         if(pageNumber > 1 && searchQuery===''){
    //             setPaginationMultiplyNum((pageNumber-1)*perPage)
    //             }else{
    //             setPaginationMultiplyNum(0)
    //         }
    //     }
    // }, [Data, breakdownData, searchQuery])


    

    const [breakdown_top, set_breakdown_top] = useState(null);
    const [total_data_top, set_total_data_top] = useState(null);
    const get_target_offer_report = async(dates=false) => {
        setBreakdownReportLoading(true)

        try {
            // console.log("target offer selected :", targetOffer)
            let token = localStorage.getItem('access_token')
            var request_options = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+token
                }
            }

            var returned_selection_dates = process_selection_dates(dates, selectionRange)
            var start_date = returned_selection_dates[0]
            var end_date = returned_selection_dates[1]            
            if(!start_date || !end_date){
                setBreakdownReportLoading(false)
                return
            }
            // console.log(start_date, end_date)
            let response = null
            // &per_page=${perPage}&page_number=${pageNumber}
            response = await fetch(`${API_URL}/api/v1/breakdown-reports/${targetOffer}?start_date=${start_date}&end_date=${end_date}&searchParameter=${searchParameter}`, request_options)
            let res_code = response.status
            response = await response.json()
            // console.log("offer-reports data :", res_code, response)
    
            if(res_code === 200 && response){
                var breakdown_data = response.reports
                var selected_data = breakdown_data.slice(0, perPage)

                // console.log("breakdown data :", breakdown_data.length, selected_data.length, perPage)
                //  perPage*(pageNumber-1), perPage*pageNumber)

                setBackupData(breakdown_data)
                setBreakdownData(selected_data)
                set_breakdown_top(response.top_cards)
                setBreakdownReportLoading(false)
                setSearchQuery('')

                setThePaginationParameters(selected_data.length, response.total_reports)
            }else{
                if(res_code === 401 || res_code === 403){
                    toast.error(response.error)
                    setError(true)
                    localStorage.removeItem('access_token')
                    window.location.href = "/login"
                }
            }
        } catch (error) {
            console.log("Api error occurred ", error)
            if (error.response && error.response.status === 401) {
                localStorage.removeItem('access_token')
                window.location.href = "/login"
            }
        }
    }
    useEffect(() => {
        if(showBreakdownReport && targetOffer && searchParameter){
            setBreakdownData([])
            setPageNumber(1)
            get_target_offer_report() 
        }
    }, [targetOffer, searchParameter])


    // ############################# render reports to table #############################
    const handleOfferClick = (offer_name, offer_id, offer_category) => {
        setTargetOfferName(offer_name)
        setTargetOffer(offer_id)
        setTargetOfferCategory(offer_category)
        
        clear_sort_values()
        setSearchQuery('')
        clear_pagination_values()

        setBackupPageNumber(pageNumber)
        setReportBackupTotalRows(totalRows)

        // setPaginationMultiplyNum(0)
        setShowBreakdownReport(true)
    }

    const renderReports = () => {
        let reportsData = []
        console.log(Data)
        Data.map((data, index) =>  {
            reportsData.push(
                <tr key={index}>
                    <td className='bold'>{((pageNumber-1)*perPage) + (index+1)}</td>
                    <td className={`${report_classes.cursor_pointer} bold`} onClick={()=>handleOfferClick(data.offer_name, data.offer_id, data.offer_category)}>{data.offer_name}</td>
                    {/* <td>{data.totalUsers.toLocaleString()}</td> */}
                    {/* <td>{round(data.totalSessions, 0).toLocaleString()}</td> */}
                    <td>{round(data.total_clicks, 0).toLocaleString()}</td>
                    <td>{round(data.unique_clicks, 0).toLocaleString()}</td>
                    
                    <td>{round(data.conversion_count, 0).toLocaleString()}</td>
                    <td>{round( ((data.unique_clicks==0)?0:data.conversion_count*100/data.unique_clicks), 3).toLocaleString()}%</td>
                    <td>${round( ((data.unique_clicks==0)?0:data.conversion_revenue/data.unique_clicks), 2).toLocaleString()}</td>
                    {/* , "Conversions", "Conversion Rate", "EPC" */}
                    {/* <td>{data.totalAdImpressions.toLocaleString()}</td>
                    <td>${round(data.totalAdRevenue, 2).toLocaleString()}</td>
                    <td>{round(data.conversion_count, 2).toLocaleString()}</td>
                    <td>${round(data.conversion_revenue, 2).toLocaleString()}</td>
                    
                    <td>{round(data.search_impressions??0, 0).toLocaleString()}</td>
                    <td>{round(data.search_clicks??0, 0).toLocaleString()}</td>
                    <td>${round(data.search_rev??0, 2).toLocaleString()}</td> */}
                    <td>${round(data.total_rev??0, 2).toLocaleString()}</td>
                </tr>
            )
        })
        return reportsData
    }


    // ############################# render breakdown reports to table #############################
    const displaySelectTypeOfData = (data) => {
        if (searchParameter === 'country'){
            return data.country
        }else if (searchParameter === 'deviceType'){
            return data.deviceCategory
        }else if (searchParameter === 'osName'){
            return data.operatingSystem
        }
    }

    // session_source_medium - utm_src_medium
    // page_title_screen_name - page_title
    // session_campaign - utm_campaign
    // country - country
    // device_category - device_category
    // operating_system - operating_system



    // [
    //     {'_id': {'dimention': 'Ios'}, 'totalVisits': 2, 'totalUnique': 1},
    //     {'_id': {'dimention': 'Android'}, 'totalVisits': 1, 'totalUnique': 1},
    //     {'_id': {'dimention': 'macOS'}, 'totalVisits': 2, 'totalUnique': 2}
    // ]
    const renderBreakdownReports = () => {
        let test = []
        let breakdownReportsData = []
        breakdownData.forEach((data, index) =>  {
            test.push(displaySelectTypeOfData(data))
            breakdownReportsData.push(
                <tr key={index}>
                    <td className='bold'>{((pageNumber-1)*perPage) + (index+1)}</td>
                    <td className='bold'>{data?._id?.dimention} {data?._id?.dimention==""?"(no set)":""} {data?._id?.dimention==null?"N/A":""} </td>
                    {/* <td>{data.totalVisits.toLocaleString()}</td> */}
                    <td>{round(data.totalVisits, 0).toLocaleString()}</td>
                    <td>{round(data.totalUnique, 0).toLocaleString()}</td>
                    <td>{round(data.totalConverted, 0).toLocaleString()}</td>
                    <td>{round(data.conversion_rate, 3).toLocaleString()}%</td>
                    <td>${round(data.avg_epc, 2).toLocaleString()}</td>
                    <td>${round(data.conversion_revenue, 0).toLocaleString()}</td>
                    {/* {
                        (targetOfferCategory==="content arbitrage")?
                        <>
                            <td>{data.totalAdImpressions.toLocaleString()}</td>
                            <td>${round(data.totalAdRevenue, 2).toLocaleString()}</td>
                        </>
                        :
                        <></>
                    } */}
                    {/* <td>{round(data.conversion_count, 2).toLocaleString()}</td>
                    <td>${round(data.conversion_revenue, 2).toLocaleString()}</td> */}
                </tr>
            )
        })
        return breakdownReportsData
    }

    
    
    
    // ############################# handle ReportPopup toggle for target offer offer #############################
    const handleCloseReportPopup = () => {
        setSearchQuery('')
        setSearchParameter('country')

        setTargetOfferName('')
        setTargetOffer('')
        // setPaginationMultiplyNum(0)
        setShowBreakdownReport(false)
        setBreakdownData([])

        clear_sort_values()


        // console.log("reportBackupTotalRows is ", reportBackupTotalRows, Data.length, backupPageNumber)
        setPerPage(50) 
        setPageNumber(backupPageNumber)
        setRowsSent(Data.length)
        setPaginationStart(false)

        setTotalRows(reportBackupTotalRows)
        setTotalPages(Math.ceil(reportBackupTotalRows/10))

    }









    return (
        <div className={classes.container}>
            <div className={classes.main}>
                <div className={classes.main_top}>
                    {showBreakdownReport? (
                        <div className={`heading ${report_classes.cursor_pointer}`} onClick={handleCloseReportPopup}>
                            <div><img className={classes.go_back_img} src={arrow_left} alt="<"/></div>
                            {targetOfferName}
                        </div>
                    ):(
                        <div className="heading">{names.title}</div>
                    )}

                    {!loadingResults && <div className={classes.data_picker_div}>
                        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24} style={{textAlign:"center"}}>
                            {width < 1030 ? (
                                <DateRangePicker 
                                    showOneCalendar 
                                    appearance='default'
                                    ranges={predefinedRanges}
                                    value={selectionRange}
                                    onChange={handleSelect}
                                />
                            ):(
                                <DateRangePicker
                                    appearance='default'
                                    ranges={predefinedRanges}
                                    value={selectionRange}
                                    onChange={handleSelect}
                                />
                            )}
                        </Col>
                    </div>}

                    {displayEntriesDiv(width, perPage, setPerPage, setPaginationStart, setPageNumber)}
                </div>
                <div className={classes.main_middle}>
                    {success && <ToastContainer/>}
                    {error && <ToastContainer/>}
                    {
                        showBreakdownReport && breakdown_top &&
                        <Grid>
                            <Row className={`${classes.show_grid_div} show-grid`}>
                                { breakdown_top.search_params && 
                                    <>
                                    <Col xl={8} md={8} sm={12} xs={24}>
                                        <DashboardCard
                                            imageSize="20px"
                                            image={users_solid}
                                            title={"Search Clicks"}
                                            value={breakdown_top?.search_params?.search_clicks?.toLocaleString()    
                                        }/>
                                    </Col>
                                    <Col xl={8} md={8} sm={12} xs={24}>
                                        <DashboardCard
                                            imageSize="20px"
                                            image={users_solid}
                                            title={"Search Impressions"}
                                            value={breakdown_top?.search_params?.search_impressions?.toLocaleString()    
                                        }/>
                                    </Col>
                                    <Col xl={8} md={8} sm={12} xs={24}>
                                        <DashboardCard
                                            imageSize="20px"
                                            image={users_solid}
                                            title={"Search Revenue"}
                                            value={"$"+round(breakdown_top?.search_params?.search_revenue, 2)?.toLocaleString()    
                                        }/>
                                    </Col>
                                    </>
                                }
                                { breakdown_top.content_params && 
                                    <>
                                    <Col xl={8} md={8} sm={12} xs={24}>
                                        <DashboardCard
                                            imageSize="20px"
                                            image={users_solid}
                                            title={"Content Impressions"}
                                            value={breakdown_top?.content_params?.content_impression?.toLocaleString()    
                                        }/>
                                    </Col>
                                    <Col xl={8} md={8} sm={12} xs={24}>
                                        <DashboardCard
                                            imageSize="20px"
                                            image={users_solid}
                                            title={"Content Revenue"}
                                            value={"$"+round(breakdown_top?.content_params?.content_revenue, 2)?.toLocaleString()    
                                        }/>
                                    </Col>
                                    </>
                                }
                                { breakdown_top.cpa_params && 
                                    <>
                                    <Col xl={8} md={8} sm={12} xs={24}>
                                        <DashboardCard
                                            imageSize="20px"
                                            image={users_solid}
                                            title={"CPA Count"}
                                            value={breakdown_top?.cpa_params?.cpa_count?.toLocaleString()    
                                        }/>
                                    </Col>
                                    <Col xl={8} md={8} sm={12} xs={24}>
                                        <DashboardCard
                                            imageSize="20px"
                                            image={users_solid}
                                            title={"CPA Revenue"}
                                            value={"$"+round(breakdown_top?.cpa_params?.cpa_revenue, 2)?.toLocaleString()    
                                        }/>
                                    </Col>
                                    </>
                                }
                                { breakdown_top.search_params && 
                                <Col xl={8} md={8} sm={12} xs={24}>
                                    <DashboardCard
                                        imageSize="20px"
                                        image={users_solid}
                                        title={"Total Revenue"}
                                        value={"$"+round(breakdown_top?.total_revenue, 2)?.toLocaleString()    
                                    }/>
                                </Col>
                                }
                            </Row>
                        </Grid>
                    }
                    {showBreakdownReport ? (
                        <div className={classes.table_div}>
                            <ResponsiveTable loadingResults={loadingResults}>
                                <thead>
                                    <tr>
                                        <th key={0}>#</th>
                                        <th key={1}>
                                            {/* <div className={classes.search_icon}> 
                                                <img src={search_icon} alt="search" />
                                                <input disabled={breakdownReportLoading} type="text" placeholder='Search' value={searchQuery} onChange={handleSearchQuery}/> 
                                            </div> */}
                                            <div className={classes.dropdown}>
                                                <select value={searchParameter} onChange={handleSearchParameterChange}>
                                                    {/* <option value="sessionSourceMedium">Session Source / Medium</option> */}
                                                    {/* <option value="pageTitle">Page Title Screen Name</option> */}
                                                    {/* <option value="utm_campaign">Session Campaign</option> */}
                                                    <option value="country">Country</option>
                                                    <option value="deviceType">Device Category</option>
                                                    <option value="osName">Operating System</option>
                                                    <option value="utm_medium">Sub ID</option>
                                                </select>
                                            </div>
                                        </th>
                                        <CustomReportHeaders
                                            header_names={['Total Visits', "Unique Visitors", "Conversions", "Conversion Rate", "EPC", "Total<br/>Revenue"]}
                                        />
                                    </tr>
                                </thead>


                                {breakdownData.length===0 || breakdownReportLoading ? (
                                    <div className='no_data'>
                                        {!breakdownReportLoading && 'No Data Found'}
                                        {breakdownReportLoading && <ImpulseSpinner size={30} frontColor="#02cd19" backColor="#fff"/>}
                                    </div>
                                ):(
                                    <tbody className={classes.table_data}>{renderBreakdownReports()}</tbody>
                                )}
                            </ResponsiveTable>
                        </div>
                    ):(
                        <>
                        <Grid>
                            <Row className={`${classes.show_grid_div} show-grid`}>
                                <Col xl={8} md={8} sm={12} xs={24}>
                                    <DashboardCard
                                        imageSize="20px"
                                        image={users_solid}
                                        title={"Total Visits"}
                                        value={(total_data_top?.total_visits)?.toLocaleString()}
                                    />
                                </Col>
                                <Col xl={8} md={8} sm={12} xs={24}>
                                    <DashboardCard
                                        imageSize="20px"
                                        image={users_solid}
                                        title={"Unique Visits"}
                                        value={(total_data_top?.total_unique_clics)?.toLocaleString()}
                                    />
                                </Col>
                                <Col xl={8} md={8} sm={12} xs={24}>
                                    <DashboardCard
                                        imageSize="20px"
                                        image={users_solid}
                                        title={"Total Revenue"}
                                        value={"$"+round(total_data_top?.total_revenue, 2)?.toLocaleString()}
                                    />
                                </Col>
                            </Row>
                        </Grid>
                        {(Data.length===0) ? (
                            <ResponsiveTable loadingResults={loadingResults}>
                                <thead><ReportHeaders header_names={report_table_headers}/></thead>
                                <div className='no_data'>No Data Found</div>
                            </ResponsiveTable>
                        ):(
                            <div className={classes.table_div}>
                                <ResponsiveTable loadingResults={loadingResults}>
                                    <thead><ReportHeaders header_names={report_table_headers}/></thead>
                                    <tbody className={classes.table_data}>{renderReports()}</tbody>
                                </ResponsiveTable>
                            </div>
                        )}
                        </>
                    )}
                </div>

                {/* paginatino section */}
                <div className={classes.main_bottom}>
                    <div className={report_classes.pagination_info_div}>
                        Showing {rowsSent} out of {totalRows} entries
                    </div>
                    <div className={report_classes.pagination_div}>
                        <>
                            <button onClick={(e)=>{handlePagination(e, pageNumber, totalPages, setPageNumber, setPaginationStart)}} value={pageNumber-1} className={`${report_classes.each_pagination_item} ${report_classes.each_pagination_item_left_border}`}><img className={report_classes.no_pointer_event} src={arrow_left} alt="<"/></button>
                            {Array.from(Array(totalPages>5?5:totalPages), (each_item, index) => {
                                return <button onClick={(e)=>{handlePagination(e, pageNumber, totalPages, setPageNumber, setPaginationStart)}}
                                className={`${report_classes.each_pagination_item} ${report_classes.extra_padding} 
                                ${pageNumber>=5 && `${pageNumber === totalPages? ((pageNumber-(5-(index+1))) === pageNumber) && report_classes.mark_page_number : ((pageNumber+1)-(5-(index+1)) === pageNumber) && report_classes.mark_page_number}`}
                                ${pageNumber<5 && ((index+1) === pageNumber) && report_classes.mark_page_number}`}

                                value={pageNumber>=5? `${pageNumber === totalPages? (pageNumber)-(5-(index+1)):(pageNumber+1)-(5-(index+1))}` : (index+1)}>
                                    {pageNumber>=5? `${pageNumber === totalPages? (pageNumber)-(5-(index+1)):(pageNumber+1)-(5-(index+1))}` : (index+1)}
                                </button>
                            })}
                            <button onClick={(e)=>{handlePagination(e, pageNumber, totalPages, setPageNumber, setPaginationStart)}} value={pageNumber+1}className={`${report_classes.each_pagination_item} ${report_classes.each_pagination_item_right_border}`}><img className={report_classes.no_pointer_event} src={arrow_right} alt=">"/></button>
                        </>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Reports
